.layout-right {
  min-height: 100vh;
}

.app-header {
  padding: 0 24px;
  background: #fff;

  &__title {
    float: left;
    font-size: 1.4rem;
    margin: 0;
  }

  &__right {
    float: right;
  }

  &__avatar {
    margin-right: 15px;
  }
}

.app-content {
  margin: 24px 16px 0;
  overflow: initial;

  &__inner {
    max-width: 1024px;
    margin: 0 auto;
    padding: 24px;
    background: #fff;
  }
}

.app-footer {
  text-align: center;
}
