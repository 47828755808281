.login {
  width: 300px;
  padding: 8% 0 0;
  margin: auto;
  text-align: center;

  &__logo {
    max-width: 90px;
    height: auto;
    margin-bottom: 20px;
  }

  .ant-btn-primary {
    background-color: #ea4335;
    border-color: #ea4335;

    &:hover,
    &:focus {
      background-color: #ff493a;
      border-color: #ff493a;
    }

    &:active,
    &.active {
      background-color: #d23c30;
      border-color: #d23c30;
    }
  }
}
